import React from "react";
import { Img, Button } from "./..";
import { Link } from "react-router-dom";
import { isLoggedIn } from 'constant/localStorage';

export default function MainHeader({ ...props }) {
	const kycStatus = localStorage.getItem('kycStatus');
	return (
		<header { ...props } className="flex flex-col items-center justify-start w-full fixed shadow-2xl z-10">
			<div className="flex flex-row justify-end w-full bg-white shadow-xs">
				<div className="flex flex-row justify-between items-center w-full mx-auto max-w-[1241px]">
					<Link to="/" >
						<Img src="images/logo_crop.png" alt="logo" className="w-[120px] md:w-[150px] pl-5" />
					</Link>
					{
						isLoggedIn() ?
							<Link to={ kycStatus === "pending" ? "/originator-form" : kycStatus === "awaiting" ? '/originator' : "/dashboard" } >
								<Button
									size="6xl"
									variant="gradient"
									shape="square"
									color="yellow_A700_amber_500_01"
									className="font-poppins font-semibold min-w-[170px]"
								>
									Go to Dashboard
								</Button>
							</Link> :
							<Link to="/login" >
								<Button
									size="6xl"
									variant="gradient"
									shape="square"
									color="yellow_A700_amber_500_01"
									className="font-poppins font-semibold min-w-[100px]"
								>
									Login
								</Button>
							</Link>
					}

				</div>
			</div>
		</header>
	);
}
