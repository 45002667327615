import React from "react";
import { Text, Img, Heading } from "..";

const VisionMission = () => {
	return (
		<div id="vision-mision" className="flex flex-col items-center justify-start w-full gap-5 pt-10 lg:pt-20">
			<div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-10 lg:gap-20 relative">
				<div className="flex flex-col items-start justify-start gap-5 order-last">
					<Img
						src="images/img_vision.jpg"
						alt="imageone_one"
						className="object-cover rounded-[10px] w-full h-full"
					/>
				</div>
				<div className="flex flex-col items-start justify-start gap-7">
					<Text size="5xl" as="p" className="!text-red-500 !font-poppins uppercase">
						Our Vision and Mission
					</Text>
					<Heading size="11xl" as="h2" className="!font-poppins !leading-normal">
						Visionary Excellence: Leading Fintech Transformation
					</Heading>
					<div className="flex flex-col gap-3">
						<Heading size="5xl" as="h2" className="!font-poppins">
							VISION
						</Heading>
						<Text as="p" size="2xl" className="!text-black-900 !font-poppins !font-normal !leading-7 text-justify">
							To be a dynamic and creative solution provider in
							fintech digital business funding transformation.
						</Text>
					</div>
					<div className="flex flex-col gap-3">
						<Heading size="5xl" as="h2" className="!font-poppins">
							MISSION
						</Heading>
						<Text as="p" size="2xl" className="!text-black-900 !font-poppins !font-normal !leading-7 text-justify">
							A vigorous team distinguished by
							professional subject matter experts
							share their proven knowledges and
							experiences to craft optimised and
							profitable solutions to forward looking
							business propositions. We want to strive
							paramount results in business and
							funding value propositions of our
							partner by leveraging contemporary and
							creative technology-driven solutions.
						</Text>
					</div>
				</div>
			</div>
		</div>
	);
};

export default VisionMission;