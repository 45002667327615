import React from "react";
import { Text, Button, Img } from "..";
import { Link } from "react-router-dom";

const Hero = () => {
	return (
		<div className="grid grid-cols-1 gap-10 lg:grid-cols-2 place-items-center w-full">
			<div className="flex flex-col items-start justify-start mt-[9px] gap-8 lg:gap-[55px]">
				<div className="flex flex-col  justify-start w-full gap-2">
					<Text size="3xl" as="p" className="!text-indigo-900 !font-poppins">
						Ready to transform your traditional assets into digital wealth?
					</Text>
					<Text size="8xl" as="p" className="!text-black-900 font-poppins !leading-snug font-semibold">
						Fintech Asset Pro is your gateway to the future of finance.
					</Text>
					<Text size="xl" as="p" className="w-[96%] !text-gray-500_02 !font-poppins !leading-7">
						Seamlessly transition your investments into the digital realm and harness the power of blockchain technology to optimize your portfolio. With our intuitive platform and expert support, you can begin your journey towards financial freedom today. Don't miss out on the opportunity to unlock the full potential of your assets. Join Fintech Asset Pro now and start your digital wealth journey!
					</Text>
				</div>
				<Link to="/login" >
					<Button size="4xl" className="font-poppins font-semibold !shadow-md min-w-[218px] rounded-[7px]">
						Let’s get start
					</Button>
				</Link>
			</div>
			<div className="w-full flex justify-center lg:justify-end">
				<Img
					src="images/img_meeting_deadlines_colored.png"
					alt="meetingone_one"
					className="w-[557px] object-cover"
				/>
			</div>
		</div >
	);
};

export default Hero;