import React from "react";
import { Text, Img, Heading } from "..";
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const Testimoni = () => {
	const testimonial = [
		{
			image: 'images/img_profile.svg',
			title: ' Roslan Ahmad',
			role: 'MANAGING DIRECTOR',
			desc: 'Roslan Ahmad is an accomplished professional with over 30 years of diverse experience in financial, advisory, and corporate sectors, including roles in banking, securitisation, capital markets, Shariah advisory, commodity trading, and fintech, achieving numerous world-first recognitions through innovative product structuring and profitable performance.'
		},
		{
			image: 'images/img_profile.svg',
			title: ' Roslan Ahmad',
			role: 'MANAGING DIRECTOR',
			desc: 'Roslan Ahmad is an accomplished professional with over 30 years of diverse experience in financial, advisory, and corporate sectors, including roles in banking, securitisation, capital markets, Shariah advisory, commodity trading, and fintech, achieving numerous world-first recognitions through innovative product structuring and profitable performance.'
		},
		{
			image: 'images/img_profile.svg',
			title: ' Roslan Ahmad',
			role: 'MANAGING DIRECTOR',
			desc: 'Roslan Ahmad is an accomplished professional with over 30 years of diverse experience in financial, advisory, and corporate sectors, including roles in banking, securitisation, capital markets, Shariah advisory, commodity trading, and fintech, achieving numerous world-first recognitions through innovative product structuring and profitable performance.'
		},
	];

	return (
		<div id="our-team" className="flex flex-col items-start justify-start w-full pt-20">
			<div className="flex flex-row justify-between items-center w-full mx-auto">
				<div className="flex flex-col items-start justify-start gap-7">
					<Text size="5xl" as="p" className="!text-red-500 !font-poppins uppercase">
						Our Teams
					</Text>
					<Heading size="11xl" as="h2" className="!font-poppins !leading-normal">
						Empowered Collaboration: <br />
						Driving Excellence Together
					</Heading>
				</div>
			</div>
			<Swiper
				spaceBetween={ 50 }
				slidesPerView={ 2.5 }
				breakpoints={ {
					0: {
						slidesPerView: 1,
						spaceBetween: 20,
					},
					425: {
						slidesPerView: 1.1,
						spaceBetween: 20,
					},
					768: {
						slidesPerView: 1.5,
						spaceBetween: 40,
					},
					1024: {
						slidesPerView: 2.5,
						spaceBetween: 50,
					},
				} }
				className="relative w-full py-10 md:px-5"
			>
				{ testimonial.map((data, index) => (
					<SwiperSlide key={ index } className="flex flex-col items-start justify-center bg-white shadow-lg rounded-md gap-[17px] px-10 py-16">
						<div className="flex items-center gap-2.5">
							<Img
								src={ data.image }
								alt="circleimage"
								className="h-[60px] w-[60px]"
							/>
							<div className="flex flex-col items-start justify-start gap-2">
								<Text size="4xl" as="p" className="!text-gray-700 !font-semibold !font-poppins">
									{ data.title }
								</Text>
								<Text size="xl" as="p" className="!text-gray-500 !font-poppins !font-normal">
									{ data.role }
								</Text>
							</div>
						</div>
						<Text className=" !text-gray-700_01 !font-poppins  mt-4">
							{ data.desc }
						</Text>
					</SwiperSlide>
				)) }
			</Swiper>
		</div >
	);
};

export default Testimoni;